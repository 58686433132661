import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SavedList, SavedListType, SavedListsService } from './saved-lists.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../notification-service/notification.service';
import { Observable } from 'rxjs';

export interface SavedListsDialogData {
  type: SavedListType;
  items?: string[];
}

@Component({
  selector: 'app-saved-lists',
  templateUrl: './saved-lists.component.html',
  styleUrls: ['./saved-lists.component.scss'],
})
export class SavedListsComponent {
  type: SavedListType;
  items: string[] | undefined;
  form: FormGroup;
  savedLists: Observable<SavedList[] | null>;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<SavedListsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SavedListsDialogData,
    private savedListsService: SavedListsService,
    private notify: NotificationService
  ) {
    this.type = data.type;
    this.items = data.items;
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      description: [''],
    });

    if (!this.items) {
      this.savedLists = this.savedListsService.lists$;
    }
  }

  save() {
    if (this.form.valid && this.items) {
      this.savedListsService.saveList(
        this.form.value.name,
        this.form.value.description,
        this.items,
        this.type
      );
      this.notify.success('List saved');
      this.dialogRef.close();
    }
  }

  load(list: SavedList) {
    this.dialogRef.close(list.items);
  }

  close() {
    this.dialogRef.close();
  }
}
