import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from '../notification-service/notification.service';
import { AuthService } from 'src/app/auth/service/auth.service';
import { prebuiltLists } from './prebuilt-lists';

export enum SavedListType {
  People = 'people',
  Companies = 'companies',
  Jobs = 'jobs',
  Lists = 'lists',
}

export interface SavedList {
  itemType: SavedListType;
  id: string;
  name: string;
  description?: string;
  createdAt: string;
  createdBy: string;
  items: string[];
}

@Injectable({
  providedIn: 'root',
})
export class SavedListsService {
  private listsSource = new BehaviorSubject<SavedList[] | null>(null);
  private refreshing = new BehaviorSubject<boolean>(false);

  lists$ = this.listsSource.asObservable();
  refreshing$ = this.refreshing.asObservable();

  constructor(
    private notify: NotificationService,
    private authService: AuthService
  ) {
    this.getLists();
  }

  public refresh() {
    this.getLists();
  }

  private getLists() {
    this.refreshing.next(true);
    let lists = prebuiltLists;
    try {
      localStorage.getItem('savedLists');
      const localLists = JSON.parse(localStorage.getItem('savedLists') || '[]');
      lists = lists.concat(localLists);
    } catch (e) {
      this.notify.error('Error getting saved lists');
    }
    this.listsSource.next(lists);
    this.refreshing.next(false);
  }

  public saveList(name: string, description: string, items: string[], itemType: SavedListType) {
    const newList: SavedList = {
      itemType: itemType,
      name: name,
      description: description,
      items: items,
      createdAt: new Date().toString(),
      createdBy: this.authService.getUserValue.email,
      id: this.generateShortGuid(),
    };
    const lists = this.listsSource.value || [];
    lists.push(newList);
    localStorage.setItem('savedLists', JSON.stringify(lists));
    this.listsSource.next(lists);
  }

  public deleteList(listId: string) {
    const lists = this.listsSource.value || [];
    const updatedLists = lists.filter((list) => list.id !== listId);
    localStorage.setItem('savedLists', JSON.stringify(updatedLists));
    this.listsSource.next(updatedLists);
  }

  private generateShortGuid(): string {
    return 'xxxx-xxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
