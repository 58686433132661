import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Org, InvitationsService, OrganizationsService, OrgBody } from 'ldt-identity-service-api';
import { LedgersService } from 'ldt-ledger-service-api';
import { GridApi, RowClickedEvent } from 'ag-grid-community';
import { AuthService } from 'src/app/auth/service/auth.service';
import { BtnCellRenderer } from 'src/app/shared/ag-grid-button/button-cell-renderer.component';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { IdentityOrgCreateDialogComponent } from './identity-org-create-dialog/identity-org-create-dialog.component';
import { formatNumber } from '@angular/common';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-identity-orgs',
  templateUrl: './identity-orgs.component.html',
  styleUrls: ['./identity-orgs.component.scss'],
})
export class IdentityOrgsComponent implements OnInit {
  /// AG -GRID --------------
  private simpleFilterParams: any = {
    filterOptions: ['contains'],
    suppressAndOrCondition: true,
  };
  defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    filterParams: this.simpleFilterParams,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
    autoHeight: true,
  };
  columnDefs = [
    { field: 'id', cellRenderer: 'loadingRenderer', maxWidth: 150 },
    { field: 'name', sort: 'asc' },
    {
      headerName: 'Observe',
      filter: false,
      width: 220,
      sortable: false,
      cellRenderer: BtnCellRenderer,
      cellRendererParams: {
        onClick: this.observeOrg.bind(this),
        label: 'Observe',
        display: 'compact',
      },
    },
    { field: 'status', filter: true },
    { field: 'createdAt' },
    {
      field: 'settings.ledger.capability',
      headerName: 'Ledger?',
      filter: true,
      cellRenderer: (params: any) => {
        if (params.data?.settings?.ledger?.capability === true) {
          const ledgerVersion = params.data?.settings?.ledger?.uiApiVersion || '1';
          const selectorIndicator = params.data?.settings?.ledger?.uiApiVersionSelector
            ? ' (*)'
            : '';
          const ledgerLimit = formatNumber(
            params.data?.settings?.ledger?.contactLimit,
            this.locale
          );
          return 'Yes, v' + ledgerVersion + selectorIndicator + '<br>' + ledgerLimit;
        }

        return 'No';
      },
    },
    {
      headerName: 'Moneyball?',
      filter: true,
      cellRenderer: (params: any) => {
        if (params.data?.settings?.moneyball?.capability === true) {
          let dateValue = 'No expiration';
          if (params.data?.settings?.moneyball?.expiresAt) {
            dateValue = 'Until: ' + params.data?.settings?.moneyball?.expiresAt;
          }
          return 'Yes' + '<br>' + dateValue;
        }

        if (params.data?.settings?.moneyball?.expiresAt) {
          return 'No' + '<br>Expired: ' + params.data?.settings?.moneyball?.expiresAt;
        }

        return 'No';
      },
      valueGetter: (params: any) => {
        let dateValue = '_';
        if (params.data?.settings?.moneyball?.expiresAt) {
          dateValue = params.data?.settings?.moneyball?.expiresAt;
        }

        return dateValue + '-' + (params.data?.settings?.moneyball?.capability ? '1' : '2');
      },
      cellStyle: (params: any) => {
        if (params.data?.settings?.moneyball?.expiresAt) {
          const now = dayjs();
          const targetDate = dayjs(params.data?.settings?.moneyball?.expiresAt);
          const diffInDays = targetDate.diff(now, 'day');

          let bgColor: string = '';

          if (diffInDays < 0) {
            bgColor = '#D3D3D3';
          } else if (diffInDays > 30) {
            bgColor = '#ADD8E6';
          } else {
            // Calculate the gradient from red to green
            const red = Math.min(255, Math.floor((255 * (30 - diffInDays)) / 30));
            const green = Math.min(255, Math.floor((255 * diffInDays) / 30));
            bgColor = `rgb(${red}, ${green}, 0)`;
          }

          return { backgroundColor: bgColor };
        }
        return {};
      },
    },
    { field: 'settings.people.capability', headerName: 'People?', filter: true },
    { field: 'settings.clean.capability', headerName: 'Jobs?', filter: true },
    {
      field: 'id',
      headerName: 'S3 link',
      cellRenderer: (params: { value: any }) => {
        return (
          '<a href="https://us-east-1.console.aws.amazon.com/s3/buckets/ldt-customer-deliveries?region=us-east-1&bucketType=general&prefix=' +
          params.value +
          '/" class="cell-link" target=_blank>Go to S3</a>'
        );
      },
    },
    {
      field: 'id',
      headerName: 'Usage',
      cellRenderer: (params: { value: any }) => {
        return (
          '<a href="https://grafana.gotlivedata.io/d/TO94-mBnz/org-ledgers-dashboard?refresh=5m&var-OrgId=' +
          params.value +
          '" class="cell-link" target=_blank>Grafana</a>'
        );
      },
    },
  ];
  rowData: Org[] = [];

  private gridApi: GridApi;

  components = {
    loadingRenderer: function (params: any) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
      }
    },
  };
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onFirstDataRendered(params: any) {
    // Default the status filter to active orgs only
    var instance = this.gridApi.getFilterInstance('status');
    instance?.setModel({ values: ['active'] });
    this.gridApi.onFilterChanged();
  }

  refreshing: boolean = true;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private inviteService: InvitationsService,
    private ledgerService: LedgersService,
    private orgService: OrganizationsService,
    private dialog: MatDialog,
    private notify: NotificationService,
    private router: Router,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.updateOrgs();
  }

  updateOrgs() {
    this.refreshing = true;
    this.orgService.getOrgs().subscribe({
      next: (res) => {
        this.rowData = res;
        this.refreshing = false;
      },
      error: () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
      },
    });
  }

  showCreateDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    const dialogRef = this.dialog.open(IdentityOrgCreateDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.createOrg(data);
      }
    });
  }

  createOrg(data: any) {
    // Use |any since the `settings` are intentionally obfuscated in the API
    let orgBody: OrgBody | any = {
      name: data.orgName.trim(),
      settings: {
        ledger: { capability: data.ledgerCapability, uiApiVersion: '2' },
        moneyball: { capability: data.moneyballCapability },
        clean: { capability: data.jobsCapability },
        people: { capability: data.peopleCapability },
      },
    };

    if (data.contactLimit && orgBody.settings) {
      orgBody['settings']['ledger']['contactLimit'] = data.contactLimit;
    }

    this.orgService.createOrg(orgBody).subscribe({
      next: (r) => {
        this.updateOrgs();
        this.notify.success('Org Created');

        if (data.ledgerCapability) {
          this.ledgerService.createLedger(r.id, { name: 'Default' }).subscribe({
            next: () => {
              // Nothing to do here
            },
            error: () => {
              // Fail silently, not a big deal
            },
          });
        }
        if (data.userEmail) {
          let body = {
            inviteeEmail: data.userEmail.trim(),
            inviteeRole: 'admin',
          };
          this.inviteService.createInvitation(r.id, body).subscribe({
            next: () => {
              this.notify.success('User invited');
            },
            error: () => {
              this.notify.error('Error inviting user');
            },
          });
        }
      },
      error: () => {
        this.notify.error('Error creating org');
      },
    });
  }

  onRowClicked(event: RowClickedEvent) {
    this.router.navigate(['admin/identity/orgs/', event.data.id]);
  }

  observeOrg(e: any) {
    let org: Org = e.rowData;
    this.auth.setAdministrativeOrg(org);
    this.auth.setSelectedOrgId(org.id);
    this.router.navigate(['/']);
  }
}
