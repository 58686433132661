<div class="searchContainer">
  <mat-form-field
    class="companySearch tw-bg-white tw-rounded-[5px]"
    [floatLabel]="floatLabel"
    appearance="outline">
    <mat-label>{{labelText}}</mat-label>
    <input
      matInput
      [formControl]="companyNameControl"
      [matAutocomplete]="auto"
      #autoInput="matAutocompleteTrigger"
      #inputField />

    <mat-autocomplete
      #auto="matAutocomplete"
      [panelWidth]="500"
      class="searchAutocomplete"
      classList="searchAutocomplete"
      (optionSelected)="onCompanySelected($event)">
      <mat-option *ngFor="let company of searchCompanies | async" [value]="company">
        <div class="searchCompanyItem">
          <div class="searchCompanyNameLine">
            <div>{{ company.name }}</div>
            <mat-chip-list>
              <mat-chip class="chipTicker" *ngIf="company.ticker">
                <mat-icon>show_chart</mat-icon>
                {{ company.ticker }}
              </mat-chip>
            </mat-chip-list>
          </div>
          <div class="searchCompanyInfoLine">
            <div *ngIf="company.industry">{{ company.industry }}</div>
            <div *ngIf="company.country">&#9679;</div>
            <div *ngIf="company.country">{{ company.country }}</div>
          </div>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div class="spinnerContainer">
    <mat-spinner *ngIf="searching" diameter="20"></mat-spinner>
  </div>
</div>
