import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { JobFunctions } from 'src/app/data-warehouse/dw-column';

@Component({
  selector: 'app-job-function-dropdown',
  templateUrl: './job-function-dropdown.component.html',
  styleUrls: ['./job-function-dropdown.component.scss'],
})
export class JobFunctionDropdownComponent {
  @Input() preSelectedJobFunctions: string[] | undefined = undefined;
  @Input() multiSelect: boolean = false;
  @Output() jobFunctionSelectionChanged = new EventEmitter<string[]>();

  availableJobFunctions: string[] = this.jobFunctionsAsArray();
  form: FormGroup;
  private lastEmittedValue: string[] = [];

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      selectedFunctions: [this.preSelectedJobFunctions || this.availableJobFunctions], // Form control to store multiple selected items
    });
    this.lastEmittedValue = this.form.get('selectedFunctions')?.value;
  }

  // Triggered when the dropdown panel is closed instead of single checkbox selection event
  onDropdownClosed() {
    if (this.form.get('selectedFunctions')?.value.length === 0) {
      this.form.get('selectedFunctions')?.setValue(this.availableJobFunctions);
    }

    if (
      this.hasSelectionChanged(this.lastEmittedValue, this.form.get('selectedFunctions')?.value)
    ) {
      this.jobFunctionSelectionChanged.emit(this.form.get('selectedFunctions')?.value);
      this.lastEmittedValue = this.form.get('selectedFunctions')?.value;
    }
  }

  selectAll() {
    this.form.get('selectedFunctions')?.setValue(this.availableJobFunctions);
  }

  unselectAll() {
    this.form.get('selectedFunctions')?.setValue([]);
  }

  areAllSelected() {
    return this.form.get('selectedFunctions')?.value?.length === this.availableJobFunctions.length;
  }

  areNoneSelected() {
    return (
      this.form.get('selectedFunctions')?.value === undefined ||
      this.form.get('selectedFunctions')?.value.length === 0
    );
  }

  getCollapsedStringValue(): string {
    if (this.form.get('selectedFunctions')?.value === undefined) {
      return 'All';
    }

    // If the length is zero, this isn't actually used. the `placeholder` on the mat-select is used
    // Also we should never have 0 because onDropdwonClosed() will set it to all
    if (this.form.get('selectedFunctions')?.value.length === 0) {
      return 'All';
    }

    if (this.form.get('selectedFunctions')?.value.length === this.availableJobFunctions.length) {
      return 'All';
    }

    return this.form.get('selectedFunctions')?.value.join(', ');
  }

  // Helper function to compare arrays (lastEmitted vs current selection)
  private hasSelectionChanged(lastEmitted: string[], current: string[]): boolean {
    if (lastEmitted.length !== current.length) {
      return true;
    }

    return lastEmitted.some((item) => !current.includes(item));
  }

  jobFunctionsAsArray(): string[] {
    return Object.values(JobFunctions);
  }
}
