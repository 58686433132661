import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class SettingsGeneralComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
