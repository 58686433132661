import { Component, ViewChildren, QueryList } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FilterGroupFiltersInner,
  PersonSearchFilter,
  SearchPersonsDataRequest,
  SearchPersonsDataRequestFiltersInner,
  SearchService,
} from 'ldt-people-api';
import { AuthService } from 'src/app/auth/service/auth.service';
import { ColumnCategory, ColumnType, DWColumn } from 'src/app/data-warehouse/dw-column';
import { FieldSelectorDialogComponent } from 'src/app/shared/field-selector-dialog/field-selector-dialog.component';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { PeopleColumns } from '../people-columns';
import { SearchFilter } from 'ldt-dw-reader-service-api';
import { FilterGroupComponent } from './filter-group/filter-group.component';

import * as FileSaver from 'file-saver';
import * as dayjs from 'dayjs';
import { stringify } from 'querystring';

enum MatchType {
  Fuzzy = 'fuzzy',
  Exact = 'exact',
  Exists = 'exists',
}

export interface UIFilter {
  field: DWColumn;
  filter: PersonSearchFilter;
}

interface FilterGroup {
  operator: 'and' | 'or';
  filters: Array<UIFilter | FilterGroup>;
  isPastJobsGroup?: boolean; // identify 'past jobs' groups
}

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent {
  orgId: string;
  showFilters: boolean = true;
  refreshing: boolean = false;
  countUpdating: boolean = false;
  searchCount: number = 0;
  downloading: boolean = false;

  uiFilters: UIFilter[] = [];
  columns: DWColumn[] = PeopleColumns;
  categories: ColumnCategory[] = [
    {
      name: 'personal',
      displayName: 'Personal',
      description: 'Personal information',
      displayColumn: 1,
    },
    {
      name: 'current job',
      displayName: 'Current Job',
      description: 'Information about the current job',
      displayColumn: 2,
    },
    {
      name: 'past jobs',
      displayName: 'Any Jobs',
      description:
        "Information about any job in people's history, possibly including the current job(s)",
      displayColumn: 3,
    },
    {
      name: 'education',
      displayName: 'Education',
      description: 'Information about education',
      displayColumn: 1,
    },
    {
      name: 'metadata',
      displayName: 'Timestamps',
      description: 'Timestamps for this record',
      displayColumn: 1,
    },
  ];

  rootFilterGroup: FilterGroup = {
    operator: 'and',
    filters: [],
  };

  constructor(
    private dialog: MatDialog,
    private notify: NotificationService,
    private peopleService: SearchService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    let orgId = this.route.parent?.snapshot.paramMap.get('orgId');
    if (!orgId) {
      orgId = this.authService.getSelectedOrgIdValue;
      if (!orgId) {
        this.notify.error('Invalid path');
        this.router.navigateByUrl('/main');
      }
    }
    this.orgId = orgId;
  }

  /**
   * Opens a modal dialog to select filter based on the given category, then updates filter arrays to add this filter.
   */
  showSelectorModal(isPastJobsGroup: boolean): Promise<string | undefined> {
    return new Promise((resolve) => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '1200px';
      dialogConfig.height = '80%';

      try {
        // if isPastJobsGroup is true, only show filters from the 'past jobs' category
        if (isPastJobsGroup) {
          const category = this.categories.filter((c) => c.name === 'past jobs');
          dialogConfig.data = {
            categories: category,
            fields: this.columns,
          };
        } else {
          dialogConfig.data = { categories: this.categories, fields: this.columns };
        }
        const dialogRef = this.dialog.open(FieldSelectorDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((data) => {
          resolve(data);
        });
      } catch (err) {
        console.error(err);
        this.notify.error('Error adding filter. Please try again later.');
        resolve(undefined);
      }
    });
  }

  /**
   * Handles data (single filter name) returned from the field selector dialog and adds the selected filter to the appropriate group.
   * @param filterName - filterName to be handled, e.g. 'position.company.name'.
   * @param groupComponent - the group component to which the filter should be added.
   */
  handleDataFromDialog(filterName: string, groupComponent: FilterGroup): void {
    const isRootGroup = groupComponent === this.rootFilterGroup;
    const foundFilter = this.columns.find((f) => f.name === filterName);

    if (foundFilter) {
      const newFilter: UIFilter = {
        field: foundFilter,
        filter: {
          field: foundFilter.name,
          type: SearchFilter.TypeEnum.Must,
          match_type: this.matchTypesAsObject(foundFilter.name)[0],
        },
      };

      // Check if the groupComponent is a 'past jobs' group; if yes, add the filter directly to this group
      if (groupComponent.isPastJobsGroup) {
        groupComponent.filters.push(newFilter);
      } else {
        // Check if the filter belongs to the 'past jobs' category
        const isPastJobsFilter = foundFilter.group === 'past jobs';

        // Get the filters array based on the type of groupComponent
        const groupFilters = this.getFiltersArray(groupComponent);

        if (isPastJobsFilter) {
          // Check if there is an existing 'past jobs' group within this group
          let pastJobsGroup = this.findPastJobsGroup(groupFilters);

          // if current group is empty, add new filter directly to this group and convert it to a 'past jobs' group
          if (groupFilters.length === 0 && !isRootGroup) {
            groupFilters.push(newFilter);
            groupComponent.isPastJobsGroup = true;
            return;
          }

          pastJobsGroup = { operator: 'and', filters: [newFilter], isPastJobsGroup: true };
          groupFilters.push(pastJobsGroup);
        }

        // Add to the normal group
        else {
          groupComponent.filters.push(newFilter);
        }
      }
    } else {
      console.error(`Filter ${filterName} does not exist.`);
    }
  }

  /**
   * Helper method to find an existing 'past jobs' group in the given filters array.
   */
  private findPastJobsGroup(filters: Array<UIFilter | FilterGroup>): FilterGroup | undefined {
    return filters.find(
      (g) => this.isFilterGroup(g) && (g as FilterGroup).isPastJobsGroup && g.operator === 'and' // Optional: Add more checks to ensure the correct group
    ) as FilterGroup;
  }

  isFilterGroup(item: UIFilter | FilterGroup): item is FilterGroup {
    return (item as FilterGroup).operator !== undefined;
  }

  /**
   * Helper method to get the filters array from a FilterGroup or FilterGroupComponent.
   */
  private getFiltersArray(
    groupComponent: FilterGroupComponent | FilterGroup
  ): Array<UIFilter | FilterGroup> {
    return groupComponent instanceof FilterGroupComponent
      ? groupComponent.group.filters
      : groupComponent.filters;
  }

  handleFilterAdded(event: { component: FilterGroupComponent; isPastJobsGroup: boolean }): void {
    if (!event.component) {
      console.error('Group is undefined, cannot add filter.');
      return;
    }

    // Open the filter selection dialog and pass the `isPastJobsGroup` value
    this.showSelectorModal(event.isPastJobsGroup).then((selectedFilterName: string | undefined) => {
      if (selectedFilterName) {
        this.handleDataFromDialog(selectedFilterName, event.component.group);
      }
    });
  }

  deleteAllFilters() {
    this.rootFilterGroup.filters = [];
  }

  // TODO: Temporary until backend doesn't require job groups
  jobsGroups: number;
  rootFilterGroupToAPIFilters(): SearchPersonsDataRequestFiltersInner {
    this.jobsGroups = 0;
    const topFilter: SearchPersonsDataRequestFiltersInner = {
      filters: this.filterGroupToAPIFilters(this.rootFilterGroup),
      operator: this.rootFilterGroup.operator,
    };

    return topFilter;
  }

  filterGroupToAPIFilters(group: FilterGroup): FilterGroupFiltersInner[] {
    const filters: FilterGroupFiltersInner[] = [];
    if (group.isPastJobsGroup) {
      this.jobsGroups++;
    }

    group.filters.forEach((filter, idx) => {
      if ('filters' in filter) {
        // This is a FilterGroup
        filter = filter as FilterGroup;
        filters.push({
          operator: filter.operator,
          filters: this.filterGroupToAPIFilters(filter),
        });
      } else {
        // This is a UIFilter
        filter = filter as UIFilter;
        if (filter.field.name.startsWith('jobs.')) {
          filter.filter.job_group = this.jobsGroups;
        }
        filters.push(filter.filter);
      }
    });
    return filters;
  }

  runSearch() {
    this.refreshing = true;
    this.countUpdating = true;

    const req: SearchPersonsDataRequest = {
      filters: [this.rootFilterGroupToAPIFilters()],
      size: 0,
    };

    this.peopleService.searchPersonsData(this.orgId, req).subscribe({
      next: (data) => {
        this.searchCount = data.count || 0;
        this.refreshing = false;
        this.countUpdating = false;
      },
      error: (err) => {
        console.error('Error searching for people:', err);
        this.notify.error('Error searching for people');
        this.refreshing = false;
        this.countUpdating = false;
      },
    });
  }

  download() {
    if (this.searchCount > 10000) {
      this.notify.error(
        'You can only download up to 10,000 records at a time. Please narrow your search.'
      );
      return;
    }

    this.downloading = true;

    const req: SearchPersonsDataRequest = {
      filters: [this.rootFilterGroupToAPIFilters()],
      size: 0,
    };

    this.peopleService.downloadPersonsData(this.orgId, req).subscribe({
      next: (response) => {
        const csvData = response as unknown as string; // type assertion to avoid using 'any'
        const data = new Blob([csvData], { type: 'text/csv' });
        FileSaver.saveAs(data, 'livedata-download-' + dayjs().format('YYYYMMDD-HHmmss') + '.csv');
        this.downloading = false;
      },
      error: (err) => {
        console.error('Error searching for people:', err);
        this.notify.error('Error searching for people');
        this.refreshing = false;
        this.countUpdating = false;
      },
    });
  }

  copyFiltersToClipboard() {
    const filters = [this.rootFilterGroupToAPIFilters()];

    const filtersString = JSON.stringify(filters, null, 2);
    navigator.clipboard.writeText(filtersString).then(
      () => {
        this.notify.success('Filters copied to clipboard');
      },
      () => {
        this.notify.error('Error copying filters to clipboard');
      }
    );
  }

  /**
   * Helper methods
   */

  focusInput(inputField: HTMLInputElement) {
    inputField.focus();
  }

  loadQuickBuild($event: UIFilter[]) {
    this.uiFilters = $event;
    this.runSearch();
  }

  /**
   * Returns an array of match types as objects for the specified field.
   * @param field - The field for which to retrieve the match types.
   * @returns An array of match types as objects.
   */
  matchTypesAsObject(field: string): MatchType[] {
    let values = Object.values(MatchType);
    const col = this.columns.find((c) => c.name === field);

    // Date fields don't use `exact` match type
    if (col && col.type === ColumnType.date) {
      values = values.filter((v) => v !== MatchType.Exact);
    }

    // Enum and boolean fields don't use `fuzzy` match type
    if (
      col &&
      (col.type === ColumnType.jobfunction ||
        col.type === ColumnType.joblevel ||
        col.type === ColumnType.boolean)
    ) {
      values = values.filter((v) => v !== MatchType.Fuzzy);
    }

    return values;
  }

  addBadgeToFilter(event: { filter: UIFilter }) {
    const filterIndex = this.uiFilters.findIndex((f) => f === event.filter);
    if (filterIndex !== -1) {
      this.uiFilters[filterIndex] = event.filter;
    }
  }

  removeBadgeFromFilter(event: { filter: UIFilter }) {
    const filterIndex = this.uiFilters.findIndex((f) => f === event.filter);
    if (filterIndex !== -1) {
      this.uiFilters[filterIndex] = event.filter;
    }
  }

  // FILTER CHIPS CODE
  toggleFiltersContent() {
    this.showFilters = !this.showFilters;
  }

  removeFilterChip(targetFilter: UIFilter) {
    this.removeFilterRecursive(this.rootFilterGroup, targetFilter);
  }

  private isUIFilter(filter: UIFilter | FilterGroup): filter is UIFilter {
    return 'field' in filter && 'filter' in filter;
  }

  private removeFilterRecursive(group: FilterGroup, targetFilter: UIFilter): boolean {
    for (let i = 0; i < group.filters.length; i++) {
      const filter = group.filters[i];

      if (this.isUIFilter(filter) && filter === targetFilter) {
        // Remove the target filter from the filters array
        group.filters.splice(i, 1);
        return true;
      } else if (!this.isUIFilter(filter)) {
        // Recursively search in nested FilterGroups
        const found = this.removeFilterRecursive(filter, targetFilter);
        if (found) {
          // If the nested FilterGroup becomes empty after removal, optionally remove it
          if (filter.filters.length === 0) {
            group.filters.splice(i, 1);
          }
          return true;
        }
      }
    }
    return false;
  }
  // END FILTER CHIPS CODE
}
