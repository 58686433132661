import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Company,
  CreateEmployeeTenureReportRequest,
  FindCompany200Response,
} from 'ldt-moneyball-api';
import * as Highcharts from 'highcharts';

// TODO for parallel coordinates on radar chart, but not working
import HC_More from 'highcharts/highcharts-more';
HC_More(Highcharts);

import { MoneyballService, SankeyNode } from '../moneyball/moneyball.service';

import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, forkJoin, timer } from 'rxjs';
import { NotificationService } from '../shared/notification-service/notification.service';
import { trigger, transition, style, animate } from '@angular/animations';
import {
  arrDepChartNoSeries,
  churnRateByMonthChart,
  sankeyChartNoSeries,
  tenureByMonthChartNoSeries,
  tenureCohortChart,
  wheelChartNoSeries,
} from './chart-options';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import * as moment from 'moment';
import { JobFunctions, JobLevels } from '../data-warehouse/dw-column';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  SavedListsComponent,
  SavedListsDialogData,
} from '../shared/saved-lists/saved-lists.component';
import { SavedListType, SavedListsService } from '../shared/saved-lists/saved-lists.service';

// All the UI settings/options on the page. Used for generating querystring
// and in the future for persisting in user/org/session storage
export interface BattleUrlSettings {
  companies: string[];
  jobFunctions?: JobFunctions[] | undefined;
  jobLevels?: JobLevels[] | undefined;

  // Chart-level options
  tenureStatus?: 'current' | 'departed';
  headcountValueType?: 'percentage' | 'number';
  turnoverByMonthChartValueType?: 'departures' | 'arrivals' | 'turnover';
  tenureCohortSize?: '1' | '2' | '3';
}

// This is the data structure that we use to track the companies that are selected
// We store the colorIndex to be used across all charts and cache the sankey data
// because it has to be combined with all other sankey data in one data set
interface ChartedCompany {
  company: Company;
  colorIndex: number;
  sankeyData?: SankeyNode[];
}

@Component({
  selector: 'app-moneyball-battle',
  templateUrl: './moneyball-battle.component.html',
  styleUrls: ['./moneyball-battle.component.scss'],
  animations: [
    trigger('opacityLeave', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('100ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class MoneyballBattleComponent implements OnInit, OnDestroy {
  // Configuration options
  maxCompanies: number = 10;
  animationDuration = 8000;
  maxSankeyPoints = 50;

  companies: { [key: string]: ChartedCompany } = {};

  // Track all the chart objects and associated data subscriptions
  charts: { [key: string]: { chart: Highcharts.Chart; subscriptions: Subscription } } = {};

  // Filter options
  dateRange: [Date, Date] = [
    new Date(Date.UTC(2022, 0, 1, 0, 0, 0)),
    new Date(Date.UTC(2024, new Date().getMonth() + 1, 1, 0, 0, 0)),
  ];

  // Chart-level options and defaults
  defaultTenureCohortSize: '2' = '2';
  selectedHeadcountChartValueType: 'percentage' | 'number' = 'percentage';
  selectedTenureChartStatus: 'current' | 'departed' = 'current';
  selectedTurnoverByMonthChartValueType: 'departures' | 'arrivals' | 'turnover' = 'departures';
  selectedTenureCohortSize: '1' | '2' | '3' = this.defaultTenureCohortSize;

  appliedJobFunctions: JobFunctions[] | undefined = undefined;
  selectedJobFunctions: JobFunctions[] | undefined = undefined;

  appliedJobLevels: JobLevels[] | undefined = undefined;
  selectedJobLevels: JobLevels[] | undefined = undefined;

  tooltipContent: string = `
  Churn Rate: % of employees leaving the company
  Hiring Rate: % of new employees joining the company
  Turnover Rate: Churn Rate + Hiring Rate
  `;

  showCharts: boolean = false;

  Highcharts: typeof Highcharts = Highcharts;

  constructor(
    private moneyballService: MoneyballService,
    private router: Router,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private dialog: MatDialog,
    private savedListsService: SavedListsService
  ) {}

  ngOnInit(): void {
    // Set global chart options for the page
    Highcharts.setOptions({
      colors: [
        '#FF5733',
        '#FFCA3A',
        '#8AC926',
        '#1982C4',
        '#6A4C93',
        '#FFB5A7',
        '#FFD166',
        '#73D2DE',
        '#F4A261',
        '#E76F51',
      ],
      lang: {
        thousandsSep: ',',
      },
    });
  }

  companySelected(event: { company: Company; identifier: string }) {
    if (!event) return;
    if (!event.company) return;

    // if company ID is duplicated, bail and show error
    if (event.company.id in this.companies) {
      this.notify.error('Company already selected. Please select a different company.');
      return;
    }

    // We assign a color when we create the entry, and persist it so that adding/removing series
    // maintains the same color for each company (at least for this session)
    this.companies[event.company.id] = {
      company: event.company,
      colorIndex: this.getNextColor(),
    };

    this.updateUrlFromCurrentSettings();
    this.addCompanyToCharts(this.companies[event.company.id]);
  }

  removeCompanyFromList(id: string) {
    delete this.companies[id];
    this.removeCompanyFromCharts(id);
    this.updateUrlFromCurrentSettings();
  }

  companiesAsArray(): Company[] {
    return Object.keys(this.companies).map((c) => this.companies[c].company);
  }

  removeCompanyFromCharts(companyID: string) {
    // for each chart, remove the series with the company id
    Object.keys(this.charts).forEach((key) => {
      this.charts[key].chart.series.forEach((series) => {
        if (series.options.custom?.id === companyID) {
          series.remove();
        }
      });
    });

    // The sankey draws from the companies dict, so just redrawing is fine since we deleted this company
    this.redrawSankeyCharts();
  }

  // Updates all charts for a given company
  addCompanyToCharts(company: ChartedCompany) {
    this.updateEmployeeCountChart(company);
    this.updateTenureChart(company);
    this.updateSankeyChart(company);
    this.updateTenureCohortChart(company);
    this.updateTurnoverRateChart(company);
  }

  updateEmployeeCountChart(company: ChartedCompany) {
    if (!company) return;

    const relativeData = this.selectedHeadcountChartValueType === 'percentage';

    const sub = this.moneyballService
      .getHistoricalEmployeeCounts(
        [company.company.id!],
        this.dateRange[0],
        this.dateRange[1],
        this.appliedJobFunctions,
        this.appliedJobLevels,
        relativeData
      )
      .subscribe({
        next: (res) => {
          let caption = 'Source: Live Data Technologies';
          if (relativeData) {
            caption += '<br/>Growth rate since ' + moment(this.dateRange[0]).format('MMM YYYY');
          }
          if (this.appliedJobFunctions && this.appliedJobFunctions.length > 0) {
            caption += '<br/>Job Functions: ' + this.appliedJobFunctions.join(', ');
          }
          if (this.appliedJobLevels && this.appliedJobLevels.length > 0) {
            caption += '<br/>Job Levels: ' + this.appliedJobLevels.join(', ');
          }
          this.charts['arrDep'].chart.setCaption({
            text: caption,
          });

          const data =
            this.selectedHeadcountChartValueType === 'percentage'
              ? res.map((point) => [point[0], point[1] * 100]) // convert to percentage
              : res; // keep as number otherwise

          if (relativeData) {
            this.charts['arrDep'].chart.setTitle({ text: 'Headcount Growth' });
          } else {
            this.charts['arrDep'].chart.setTitle({ text: 'Headcount' });
          }

          this.charts['arrDep'].chart.addSeries({
            name: company.company.name,
            type: 'spline',
            data: data,
            custom: { id: company.company.id },
            colorIndex: company.colorIndex,
          });
        },
        error: () => {
          this.notify.error('Error getting data for ' + company.company.name);
        },
      });

    this.charts['arrDep'].subscriptions.add(sub);
  }

  updateTenureChart(company: ChartedCompany) {
    if (!company) return;

    const sub = this.moneyballService
      .getHistoricalTenureData(
        company.company.id!,
        this.dateRange[0],
        this.dateRange[1],
        this.selectedTenureChartStatus as CreateEmployeeTenureReportRequest.StatusEnum,
        this.appliedJobFunctions,
        this.appliedJobLevels
      )
      .subscribe({
        next: (res) => {
          let caption = 'Source: Live Data Technologies';
          if (this.appliedJobFunctions && this.appliedJobFunctions.length > 0) {
            caption += '<br/>Job Functions: ' + this.appliedJobFunctions.join(', ');
          }
          if (this.appliedJobLevels && this.appliedJobLevels.length > 0) {
            caption += '<br/>Job Levels: ' + this.appliedJobLevels.join(', ');
          }
          this.charts['tenure'].chart.setCaption({
            text: caption,
          });

          this.charts['tenure'].chart.addSeries({
            name: company.company.name,
            type: 'line',
            data: res,
            custom: { id: company.company.id },
            colorIndex: company.colorIndex,
          });
        },
        error: () => {
          // This means the line won't be drawn, but don't call attention to it
        },
      });

    this.charts['tenure'].subscriptions.add(sub);
  }

  updateTenureCohortChart(company: ChartedCompany) {
    if (!company) return;

    // Get the cohort size as a number - use default value if we don't have a valid number
    let cohortSize: number = +this.selectedTenureCohortSize;
    if (isNaN(cohortSize)) {
      cohortSize = +this.defaultTenureCohortSize;
      this.selectedTenureCohortSize = this.defaultTenureCohortSize;
    }

    this.charts['tenureCohort'].chart.xAxis[0].update({
      categories: this.createTenureCohortCategories(cohortSize),
    });

    const sub = this.moneyballService
      .getBucketedTenureData(
        company.company.id!,
        cohortSize,
        this.appliedJobFunctions,
        this.appliedJobLevels
      )
      .subscribe({
        next: (res: any) => {
          let caption = 'Source: Live Data Technologies';
          if (this.appliedJobFunctions && this.appliedJobFunctions.length > 0) {
            caption += '<br/>Job Functions: ' + this.appliedJobFunctions.join(', ');
          }
          if (this.appliedJobLevels && this.appliedJobLevels.length > 0) {
            caption += '<br/>Job Levels: ' + this.appliedJobLevels.join(', ');
          }
          this.charts['tenureCohort'].chart.setCaption({
            text: caption,
          });

          this.charts['tenureCohort'].chart.addSeries({
            name: company.company.name,
            type: 'line',
            data: res,
            custom: { id: company.company.id },
            colorIndex: company.colorIndex,
          });
        },
        error: () => {
          // This means the line won't be drawn, but don't call attention to it
        },
      });

    this.charts['tenureCohort'].subscriptions.add(sub);
  }

  updateTurnoverRateChart(company: ChartedCompany) {
    if (!company) return;

    const sub = this.moneyballService
      .getHistoricalTurnoverRates(
        company.company.id!,
        this.dateRange[0],
        this.dateRange[1],
        this.selectedTurnoverByMonthChartValueType,
        this.appliedJobFunctions,
        this.appliedJobLevels
      )
      .subscribe({
        next: (res: any) => {
          let caption = 'Source: Live Data Technologies';
          if (this.appliedJobFunctions && this.appliedJobFunctions.length > 0) {
            caption += '<br/>Job Functions: ' + this.appliedJobFunctions.join(', ');
          }
          if (this.appliedJobLevels && this.appliedJobLevels.length > 0) {
            caption += '<br/>Job Levels: ' + this.appliedJobLevels.join(', ');
          }
          this.charts['turnoverRateByMonth'].chart.setCaption({
            text: caption,
          });

          let axisTitle = '% of employees that ';
          switch (this.selectedTurnoverByMonthChartValueType) {
            case 'departures':
              axisTitle += 'departed';
              break;
            case 'arrivals':
              axisTitle += 'arrived';
              break;
            case 'turnover':
              axisTitle += 'arrived or departed';
              break;
          }

          this.charts['turnoverRateByMonth'].chart.yAxis[0].update({
            title: {
              text: axisTitle,
            },
          });

          this.charts['turnoverRateByMonth'].chart.addSeries({
            name: company.company.name,
            type: 'line',
            data: res,
            custom: { id: company.company.id },
            colorIndex: company.colorIndex,
          });
        },
        error: () => {
          // This means the line won't be drawn, but don't call attention to it
        },
      });

    this.charts['turnoverRateByMonth'].subscriptions.add(sub);
  }

  updateSankeyChart(company: ChartedCompany) {
    if (!company) return;

    const sub = this.moneyballService
      .getSankeyData(company.company, this.dateRange[0], this.dateRange[1], 10)
      .subscribe({
        next: (res: SankeyNode[]) => {
          this.companies[company.company.id!].sankeyData = res;
          this.redrawSankeyCharts();
        },
        error: () => {
          // This means the line won't be drawn, but don't call attention to it
        },
      });

    this.charts['fromTo'].subscriptions.add(sub);
  }

  playHeadcountChart() {
    this.charts['arrDep'].chart.series.forEach((series) => {
      series.update({
        type: 'spline',
        animation: {
          duration: this.animationDuration,
          easing: 'easeOutBounce',
        },
      });
      series.animate();
    });
  }

  playTenureByMonthChart() {
    this.charts['tenure'].chart.series.forEach((series) => {
      series.update({
        type: 'line',
        animation: {
          duration: this.animationDuration,
          easing: 'easeOutBounce',
        },
      });
      series.animate();
    });
  }

  playChurnChart() {
    this.charts['turnoverRateByMonth'].chart.series.forEach((series) => {
      series.update({
        type: 'line',
        animation: {
          duration: this.animationDuration,
          easing: 'easeOutBounce',
        },
      });
      series.animate();
    });
  }

  onDateRangeChanged(event: [moment.Moment, moment.Moment]) {
    this.dateRange = [
      new Date(Date.UTC(event[0].year(), event[0].month(), 1, 0, 0, 0)),
      new Date(Date.UTC(event[1].year(), event[1].month(), 1, 0, 0, 0)),
    ];

    this.resetChartData('arrDep');
    this.resetChartData('tenure');
    this.resetChartData('turnoverRateByMonth');
    this.resetChartData('fromTo');
    this.resetChartData('poach');
    Object.keys(this.companies).forEach((c) => {
      this.companies[c].sankeyData = undefined;
    });

    Object.keys(this.companies).forEach((c) => {
      this.updateEmployeeCountChart(this.companies[c]);
      this.updateTenureChart(this.companies[c]);
      this.updateSankeyChart(this.companies[c]);
      this.updateTurnoverRateChart(this.companies[c]);
    });
  }

  // Get the next color in the highcharts theme that isn't already used
  getNextColor(): number {
    const colors = Highcharts.getOptions()?.colors;
    const usedColors = Object.keys(this.companies).map((c) => this.companies[c]?.colorIndex);
    if (colors) {
      for (let i = 0; i < colors.length; i++) {
        if (!usedColors.includes(i)) {
          return i;
        }
      }
    }
    return 0;
  }

  redrawSankeyCharts() {
    if (this.charts['fromTo'].chart.series.length > 0) {
      this.charts['fromTo'].chart.series[0].remove(false);
    }
    if (this.charts['poach'].chart.series.length > 0) {
      this.charts['poach'].chart.series[0].remove(false);
    }

    const allData = Object.keys(this.companies)
      .filter((c) => c && this.companies[c] && this.companies[c].sankeyData)
      .map((c) => this.companies[c].sankeyData as SankeyNode[])
      .flat()
      .sort((a, b) => b[2] - a[2]);
    let filteredData: SankeyNode[] = allData;
    let wheelData = [];

    // Remove the companies we're charting from the sources and destinations. LEaving them makes the chart
    // look weird, plus we have the lower chart to show the inter-company moves
    const companyNames = this.companiesAsArray().map((c) => c.name);
    filteredData = allData
      .filter((item) => {
        if (!item) return false;

        const interCompanyMove =
          companyNames.includes(item[0].trim()) && companyNames.includes(item[1].trim());

        return !interCompanyMove;
      })
      .slice(0, this.maxSankeyPoints);

    // For the wheel data, we're only showing the selected companies (that we didn't show in the above sankey)
    wheelData = allData.filter((item) => {
      const interCompanyMove =
        companyNames.includes(item[0].trim()) && companyNames.includes(item[1].trim());

      // The data appends a ` ` for the sankey chart - ignore those data points here
      return interCompanyMove && !item[0].endsWith(' ') && !item[1].endsWith(' ');
    });

    let caption = 'Source: Live Data Technologies';
    caption +=
      '<br/>Top inter-company movement between ' +
      moment(this.dateRange[0]).format('MMM YYYY') +
      ' and ' +
      moment(this.dateRange[1]).format('MMM YYYY');

    this.charts['poach'].chart.setCaption({
      text: caption,
    });
    this.charts['poach'].chart.addSeries({
      type: 'dependencywheel',
      keys: ['from', 'to', 'weight'],
      data: wheelData,
    });

    this.charts['fromTo'].chart.setCaption({
      text: caption,
    });
    this.charts['fromTo'].chart.addSeries({
      type: 'sankey',
      keys: ['from', 'to', 'weight'],
      data: filteredData,
    });
  }

  toggleHeadcountValueType(value: MatButtonToggleChange) {
    if (!value) return;

    this.selectedHeadcountChartValueType = value.value;
    this.updateHeadcountChartAxes(this.selectedHeadcountChartValueType);
    this.updateUrlFromCurrentSettings();

    this.resetChartData('arrDep');

    Object.keys(this.companies).forEach((c) => {
      if (this.companies[c]) {
        this.updateEmployeeCountChart(this.companies[c]);
      }
    });
  }

  toggleTurnoverRateValueType(value: MatButtonToggleChange) {
    if (!value) return;

    this.selectedTurnoverByMonthChartValueType = value.value;
    this.updateUrlFromCurrentSettings();

    const titleValues: { [key: string]: string } = {
      departures: 'Churn Rate by Month',
      arrivals: 'Hiring Rate by Month',
      turnover: 'Total Turnover Rate by Month',
    };

    this.resetChartData('turnoverRateByMonth');
    this.charts['turnoverRateByMonth'].chart.setTitle({
      text: titleValues[value.value],
    });

    Object.keys(this.companies).forEach((c) => {
      if (this.companies[c]) {
        this.updateTurnoverRateChart(this.companies[c]);
      }
    });
  }

  toggleTenureCohortSize(value: MatButtonToggleChange) {
    if (!value) return;

    let cohortSize: number = +value.value;
    this.selectedTenureCohortSize = value.value;
    if (isNaN(cohortSize)) {
      cohortSize = +this.defaultTenureCohortSize;
      this.selectedTenureCohortSize = this.defaultTenureCohortSize;
    }
    this.updateUrlFromCurrentSettings();

    this.resetChartData('tenureCohort');

    Object.keys(this.companies).forEach((c) => {
      if (this.companies[c]) {
        this.updateTenureCohortChart(this.companies[c]);
      }
    });
  }

  toggleTenureStatus(value: MatButtonToggleChange) {
    if (!value) return;

    this.selectedTenureChartStatus = value.value;
    this.updateUrlFromCurrentSettings();

    //stop subs
    this.resetChartData('tenure');

    Object.keys(this.companies).forEach((c) => {
      if (this.companies[c]) {
        this.updateTenureChart(this.companies[c]);
      }
    });
  }

  private createTenureCohortCategories(cohortSize: number): string[] {
    const result: string[] = [];
    const increment = cohortSize; // The number provided as input determines the range increment

    for (let i = 0; i < 5; i++) {
      const start = i * increment;
      const end = (i + 1) * increment;

      // The last item in the array should have a "+" sign instead of an end range
      if (i === 4) {
        result.push(`${start}+ years`);
      } else {
        result.push(`${start}-${end} years`);
      }
    }

    return result;
  }

  updateHeadcountChartAxes(headcountType: string) {
    this.charts['arrDep'].chart.update({
      yAxis: {
        labels: {
          formatter: function () {
            const pointValue = this.value as number;
            return headcountType === 'percentage'
              ? pointValue.toFixed(0) + '%'
              : pointValue.toLocaleString();
          },
        },
        title: {
          text: headcountType === 'percentage' ? 'Pct Change' : 'Number of Employees',
        },
      },
      tooltip: {
        pointFormat:
          this.selectedHeadcountChartValueType === 'percentage'
            ? '{series.name}: <b>{point.y:.2f}%</b>'
            : '{series.name}: <b>{point.y:,.0f}</b>',
      },
    } as Highcharts.Options);
  }

  saveCompanyList() {
    const dialogConfig = new MatDialogConfig<SavedListsDialogData>();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    dialogConfig.data = {
      type: SavedListType.Companies,
      items: Object.keys(this.companies),
    };
    this.dialog.open(SavedListsComponent, dialogConfig);
  }

  loadList() {
    const dialogConfig = new MatDialogConfig<SavedListsDialogData>();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    dialogConfig.data = {
      type: SavedListType.Companies,
    };
    const dialogRef = this.dialog.open(SavedListsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        const settings: BattleUrlSettings = {
          companies: data,
          jobFunctions: this.appliedJobFunctions,
          jobLevels: this.appliedJobLevels,
          tenureStatus: this.selectedTenureChartStatus,
          headcountValueType: this.selectedHeadcountChartValueType,
        };

        this.updateUrl(settings);
        this.loadPageFromSettings(settings);
      }
    });
  }

  clearCompanies() {
    const settings: BattleUrlSettings = {
      companies: [],
      jobFunctions: this.appliedJobFunctions,
      jobLevels: this.appliedJobLevels,
      tenureStatus: this.selectedTenureChartStatus,
      headcountValueType: this.selectedHeadcountChartValueType,
    };

    this.updateUrl(settings);
    this.loadPageFromSettings(settings);
  }

  resetChartData(chartName: string) {
    this.charts[chartName].subscriptions.unsubscribe();
    this.charts[chartName].subscriptions = new Subscription();
    this.removeAllSeriesFromChart(chartName);
  }

  removeAllSeriesFromChart(chartName: string) {
    while (this.charts[chartName].chart.series.length > 0) {
      this.charts[chartName].chart.series[0].remove(false);
    }
  }

  generateCurrentSettings(): BattleUrlSettings {
    const settings: BattleUrlSettings = {
      companies: Object.keys(this.companies).map((c) => this.companies[c].company.id),
      jobFunctions: this.appliedJobFunctions,
      jobLevels: this.appliedJobLevels,
      tenureStatus: this.selectedTenureChartStatus,
      headcountValueType: this.selectedHeadcountChartValueType,
    };
    return settings;
  }

  updateUrlFromCurrentSettings(): void {
    // TODO when is this used vs updateurl
    const settings = this.generateCurrentSettings();
    this.updateUrl(settings);
  }

  updateUrl(settings: BattleUrlSettings) {
    const encodedFilter = encodeURIComponent(JSON.stringify(settings));
    if (
      !this.route.snapshot.queryParams.settings ||
      this.route.snapshot.queryParams.settings !== encodedFilter
    ) {
      const url = '/moneyball/battle?settings=' + encodedFilter;
      localStorage.setItem('moneyballBattleSettings', encodedFilter);
      this.router.navigateByUrl(url);
    }
  }

  decodeUrl(encodedString: string) {
    try {
      const decodedString = decodeURIComponent(encodedString);
      return JSON.parse(decodedString);
    } catch (error) {
      this.notify.error('Error loading settings from URL.');
      console.error('Error decoding the string:', error);
      return null;
    }
  }

  goToMoneyball(companyId: string) {
    this.router.navigate(['/moneyball'], {
      queryParams: {
        id: companyId,
      },
    });
  }

  ngAfterViewInit(): void {
    // Initialize all the chart objects from the DOM
    // This is dumb, but without this, the charts won't init
    // on a fresh page load
    timer(0).subscribe(() => {
      this.charts['arrDep'] = {
        chart: Highcharts.chart('arrDepChartContainer', arrDepChartNoSeries),
        subscriptions: new Subscription(),
      };
      this.charts['tenure'] = {
        chart: Highcharts.chart('tenureChartContainer', tenureByMonthChartNoSeries),
        subscriptions: new Subscription(),
      };
      this.charts['fromTo'] = {
        chart: Highcharts.chart('fromToChartContainer', sankeyChartNoSeries),
        subscriptions: new Subscription(),
      };
      this.charts['poach'] = {
        chart: Highcharts.chart('poachingChartContainer', wheelChartNoSeries),
        subscriptions: new Subscription(),
      };
      this.charts['tenureCohort'] = {
        chart: Highcharts.chart('tenureCohortChartContainer', tenureCohortChart),
        subscriptions: new Subscription(),
      };
      this.charts['turnoverRateByMonth'] = {
        chart: Highcharts.chart('churnRateChartContainer', churnRateByMonthChart),
        subscriptions: new Subscription(),
      };

      // TODO: initialiaze tenure cohort chart with the correct xaxis categories

      // Get any filtering specified in the querystring and apply it, including loading
      // the companies and their data
      if (!this.loadFromQuerystring()) {
        if (!this.loadFromLocalStorage()) {
          this.loadFromPrebuiltLists();
        }
      }
    });
  }

  loadFromLocalStorage(): boolean {
    let loaded = false;

    try {
      const settings = localStorage.getItem('moneyballBattleSettings');
      if (settings) {
        const decodedSettings = this.decodeUrl(settings);
        if (decodedSettings) {
          this.loadPageFromSettings(decodedSettings);
          loaded = true;
        }
      }
    } catch (error) {
      console.error('Error getting data from local storage:', error);
    }

    return loaded;
  }

  loadFromPrebuiltLists(): boolean {
    let loaded = false;

    try {
      this.savedListsService.lists$.subscribe({
        next: (lists) => {
          if (!lists) return;

          const prebuiltList = lists[0];

          const mbSettings: BattleUrlSettings = {
            companies: prebuiltList.items,
          };

          this.loadPageFromSettings(mbSettings);
          loaded = true;
        },
      });
    } catch (error) {
      console.error('Error getting data from the querystring:', error);
    }

    return loaded;
  }

  loadFromQuerystring(): boolean {
    let loaded = false;

    try {
      if (this.route.snapshot.queryParams.settings) {
        const settings: BattleUrlSettings = this.decodeUrl(
          this.route.snapshot.queryParams.settings
        );
        if (settings) {
          this.loadPageFromSettings(settings);
          loaded = true;
        }
      }
    } catch (error) {
      console.error('Error getting data from the querystring:', error);

      // Clear out the querystring since it was malformed somehow
      this.updateUrlFromCurrentSettings();
    }

    return loaded;
  }

  loadPageFromInMemorySettings() {
    const settings = this.generateCurrentSettings();
    this.loadPageFromSettings(settings);
  }

  loadPageFromSettings(settings: BattleUrlSettings) {
    this.companies = {};
    this.clearAllCharts();
    this.updateUrl(settings);

    if (settings.headcountValueType) {
      this.selectedHeadcountChartValueType = settings.headcountValueType;
    }
    if (settings.tenureStatus) {
      this.selectedTenureChartStatus = settings.tenureStatus;
    }

    this.updateHeadcountChartAxes(this.selectedHeadcountChartValueType);

    forkJoin(
      settings.companies
        .filter((c) => c !== undefined)
        .map((c) => this.moneyballService.getCompany(c))
    ).subscribe({
      next: (res: (FindCompany200Response | undefined)[]) => {
        this.appliedJobFunctions = settings.jobFunctions || undefined;
        this.appliedJobLevels = settings.jobLevels || undefined;

        res.forEach((c) => {
          if (!c) return;
          if (!c.companies) return;
          if ((c.count || 0) < 1) return;

          const chartedCompany: ChartedCompany = {
            company: c.companies[0],
            colorIndex: this.getNextColor(),
          };

          this.companies[c.companies[0].id] = chartedCompany;
          this.addCompanyToCharts(chartedCompany);
        });
      },
    });
  }

  // Apply filters when dropdown closes
  onJobFunctionSelectionChanged(value: string[]) {
    this.selectedJobFunctions = value.map((v) => v as JobFunctions);
    this.applyFilters();
  }

  // Apply filters when dropdown closes
  onJobLevelSelectionChanged(value: string[]) {
    this.selectedJobLevels = value.map((v) => v as JobLevels);
    this.applyFilters();
  }

  applyFilters() {
    this.appliedJobFunctions =
      this.selectedJobFunctions?.length == Object.values(JobFunctions).length
        ? undefined
        : this.selectedJobFunctions;
    this.appliedJobLevels =
      this.selectedJobLevels?.length == Object.values(JobLevels).length
        ? undefined
        : this.selectedJobLevels;

    this.updateUrlFromCurrentSettings();
    this.clearAllCharts();
    this.loadPageFromInMemorySettings();
  }

  clearAllCharts() {
    this.resetChartData('arrDep');
    this.resetChartData('tenure');
    this.resetChartData('tenureCohort');
    this.resetChartData('turnoverRateByMonth');
    this.resetChartData('fromTo');
    this.resetChartData('poach');
  }

  ngOnDestroy() {
    Object.keys(this.charts).forEach((key) => {
      this.charts[key].subscriptions.unsubscribe();
    });
  }
}
