<!-- Create List -->
<div *ngIf="this.items">
  <h2 mat-dialog-title>Create List</h2>
  <mat-dialog-content class="tw-pt-5">
    <form [formGroup]="form" (ngSubmit)="save()" class="tw-flex tw-flex-col tw-gap-5">
      <mat-form-field appearance="outline" class="tw-w-full">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" required />
        <mat-error *ngIf="form.get('name')?.hasError('required')">Name is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="tw-w-full">
        <mat-label>Description</mat-label>
        <input matInput formControlName="description" />
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="tw-flex tw-justify-between tw-px-5 tw-pb-5">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-flat-button [disabled]="!form.valid" color="primary" (click)="save()">
      Create
    </button>
  </mat-dialog-actions>
</div>

<!-- Load List -->
<div *ngIf="!this.items">
  <h2 mat-dialog-title>Load List</h2>
  <mat-dialog-content class="tw-p-5">
    <mat-list class="tw-flex tw-flex-col tw-gap-4 tw-flex-wrap">
      <mat-list-item
        *ngFor="let item of this.savedLists | async"
        class="saved-battle-item tw-flex tw-items-center tw-flex-1 tw-border tw-border-solid tw-border-gray-300 tw-bg-gray-50 tw-rounded tw-p-2">
        <div>
          <h4 mat-line class="tw-my-1">{{ item.name }}</h4>
          <p mat-line class="tw-text-sm tw-my-1">{{ item.description }}</p>
        </div>

        <button mat-mini-fab color="primary" (click)="load(item)" class="tw-ml-auto">
          <mat-icon>folder_open</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>
  </mat-dialog-content>
  <mat-dialog-actions class="tw-flex tw-justify-between tw-px-5 tw-pb-5">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</div>
