import { SavedList, SavedListType } from './saved-lists.service';

export const prebuiltLists: SavedList[] = [
  {
    itemType: 'companies' as SavedListType,
    id: 'c4379ccc-f29c-4e99-bcf8-8e36fa318ae5',
    name: 'Big Tech',
    createdAt: '2024-09-01T00:00:00Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      'LDC-1-28a3bf17-1c92-48b2-add2-9d3a7f0921c7',
      'LDC-1-5013b123-f675-4c07-83b4-a102c99d090b',
      'LDC-1-52d98ff7-589b-44f8-ba61-a033b9894cae',
      'LDC-1-40896ef5-be2a-4725-9899-da941deddf77',
      'LDC-1-41f6f15c-31d8-4f2c-9b09-06e4d9b15604',
    ],
  },
  {
    itemType: 'companies' as SavedListType,
    id: '09a47a9a-e8fa-4392-83d5-cb3b5e0fae46',
    name: 'Chips',
    createdAt: '2024-09-01T00:00:00Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      'LDC-1-9994cf7e-eef4-4717-b6d3-4af3eb4cba1c',
      'LDC-1-4a1fe207-4d24-44e5-a062-c8554e60d418',
      'LDC-1-91ba2fb3-3b0a-48e4-8e23-0624a9aafcc3',
      'LDC-1-813a9eba-2eef-4231-b4ed-7e1c08462e9e',
    ],
  },
  {
    itemType: 'companies' as SavedListType,
    id: 'c72b0a2e-5e99-421a-8e64-32d685b69f23',
    name: 'Banks',
    createdAt: '2024-09-01T00:00:00Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      'LDC-1-705d6b3f-491b-49cb-9cc2-98070c264d70',
      'LDC-1-8a6c11e3-59b4-4242-9063-444e17a73b68',
      'LDC-1-fbd67dbb-f218-4de7-a349-c45962e898bf',
      'LDC-1-b9b1b0f7-9802-4c89-94bb-51c0fd7e5fc0',
    ],
  },
  {
    itemType: 'companies' as SavedListType,
    id: 'ed96ada4-ecab-4e9e-867d-1b306b40efcc',
    name: 'Unicorns',
    createdAt: '2024-09-01T00:00:00Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      'LDC-1-ba3f39f9-1baf-4232-b6bc-b006a6862e6e',
      'LDC-1-d5901ffc-4ef6-4a10-8542-1d1fbcf42a4a',
      'LDC-1-82fdfc40-69b6-479e-8b2d-553c2df0352d',
      'LDC-1-056d16a2-bbf0-4474-bd35-ab4a46376085',
      'LDC-1-332eb893-4100-4cf8-80ba-7e412eef3655',
    ],
  },
  {
    itemType: 'companies' as SavedListType,
    id: '89046b9c-0143-42d8-9314-ee796155da43',
    name: 'HR Software',
    createdAt: '2024-09-01T00:00:00Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      'LDC-1-b0e7f787-a84f-4ed6-b794-bc4d06fffa50',
      'LDC-1-837a4ee1-1d21-46c4-a6e0-025ef8f18285',
      'LDC-1-1648c359-b2e2-4c10-b64b-217b210e86e6',
      'LDC-1-77b5d61b-f622-4b49-b376-bb88f46ed95b',
    ],
  },
];
