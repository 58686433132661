<div id="filters-panel" class="tw-mb-5">
  <!-- quick builds -->
  <app-quick-builds
    [uiFilters]="uiFilters"
    [columns]="columns"
    (quickBuildSelected)="loadQuickBuild($event)">
  </app-quick-builds>

  <!-- filters tree expanded -->
  <div
    id="filters-expanded"
    *ngIf="showFilters"
    class="tw-overflow-x-auto tw-text-sm tw-text-gray-800 tw-rounded tw-bg-gray-100 tw-border tw-border-solid tw-border-gray-300 tw-relative tw-p-4">
    <div>
      <h3 class="tw-font-semibold tw-text-gray-800 tw-mt-0 tw-mb-2">
        Show all matching contacts where:
      </h3>

      <app-filter-group
        [group]="rootFilterGroup"
        [isRoot]="true"
        [depth]="0"
        (filterAdded)="handleFilterAdded($event)"
        class="root-filter-group">
      </app-filter-group>
    </div>

    <button
      class="icon-btn-hover tw-absolute tw-border tw-border-gray-200 tw-border-solid hover:tw-border-gray-400 tw-bottom-4 tw-right-2 tw-bg-white"
      (click)="deleteAllFilters()">
      <mat-icon class="tw-text-gray-600">delete_outline</mat-icon>
    </button>
  </div>

  <!-- filter chips -->
  <app-filter-chips
    [rootFilterGroup]="rootFilterGroup"
    [showFilters]="showFilters"
    [categories]="categories"
    (filterAdded)="handleFilterAdded($event)"
    (filterRemoved)="removeFilterChip($event)">
  </app-filter-chips>

  <div
    class="tw-w-100 tw-flex tw-justify-center"
    [ngClass]="{ 'tw-mt-[-16px]': showFilters, 'tw-mt-[-8px]': !showFilters }">
    <button
      *ngIf="showFilters"
      mat-stroked-button
      color="primary"
      class="tw-bg-white"
      matTooltip="Hide Filters Toolbar"
      matTooltipPosition="right"
      (click)="toggleFiltersContent()">
      <mat-icon class="tw-font-bold">keyboard_arrow_up</mat-icon>
    </button>
    <button
      *ngIf="!showFilters"
      mat-icon-button
      color="primary"
      class="tw-text-primary_purple-600 tw-border tw-border-solid tw-border-gray-300 tw-h-[20px] tw-rounded tw-bg-white"
      style="line-height: 20px;"
      matTooltip="Show Filters Toolbar"
      matTooltipPosition="right"
      (click)="toggleFiltersContent()">
      <mat-icon class="tw-font-bold" style="line-height: 20px;">keyboard_arrow_down</mat-icon>
    </button>
  </div>
</div>

<div class="tw-flex tw-flex-wrap tw-gap-3 tw-items-center tw-mt-5">
  <button
    mat-raised-button
    class="tw-shrink-0"
    [class.spinner]="refreshing"
    [disabled]="refreshing"
    (click)="runSearch()">
    Search
  </button>
  <button
    mat-raised-button
    class="tw-shrink-0"
    [class.spinner]="downloading"
    [disabled]="downloading"
    (click)="download()">
    Download
  </button>
  <button mat-raised-button class="tw-shrink-0" (click)="copyFiltersToClipboard()">
    Copy Filter Schema
  </button>

  <div class="count tw-flex tw-items-center">
    <mat-progress-spinner
      mode="indeterminate"
      class="tw-mr-3"
      diameter="20"
      *ngIf="countUpdating; else contactCount">
    </mat-progress-spinner>
    <ng-template #contactCount>
      <span class="badge-checked-items tw-mr-2 tw-rounded">{{searchCount | number}}</span>
    </ng-template>
    Matching Contacts
  </div>
</div>
