<div
  id="filters-collapsed"
  *ngIf="!showFilters"
  class="tw-flex tw-items-center tw-text-sm tw-text-gray-800 tw-rounded tw-bg-gray-50 tw-border tw-border-solid tw-border-gray-300 tw-p-2"
  [ngClass]="{'tw-pb-3': hasFilters(rootFilterGroup)}">
  <button
    mat-stroked-button
    color="primary"
    (click)="filterAdded.emit(rootFilterGroup)"
    class="tw-shadow-sm tw-px-2 tw-shrink-0 tw-ml-[6px] tw-mr-3 lg:tw-mr-5">
    Add Filter
  </button>

  <div
    *ngIf="hasFilters(rootFilterGroup); else noFilters"
    class="tw-flex tw-items-center tw-flex-wrap tw-gap-1">
    <div
      class="tw-flex tw-flex-wrap tw-items-center tw-mr-2 tw-gap-1"
      *ngFor="let group of getGroupedFilters(); trackBy: trackByGroupName">
      <span class="tw-font-medium tw-mr-1">{{ getCategoryDisplayName(group[0]) }}:</span>
      <ng-container *ngFor="let filter of group[1]">
        <div class="badge-selected-items tw-flex tw-items-center tw-gap-1">
          <button
            *ngIf="isFilterNameRepeated(filter, group[1])"
            class="operator-symbol"
            [matTooltip]="getFilterSymbol(filter).tooltip"
            matTooltipPosition="above">
            <span class="tw-absolute tw-left-[4px] tw-bottom-[11px]">
              {{ getFilterSymbol(filter).symbol }}
            </span>
          </button>
          <span class="tw-font-semibold">{{ filter.field.displayName }}</span>
          <mat-icon
            class="close-inside-badge hover:tw-font-semibold hover:tw-bg-primary_purple-800"
            (click)="removeFilterChip(filter)"
            tabindex="0"
            aria-hidden="false">
            close
          </mat-icon>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-template #noFilters>
    <div class="tw-font-semibold">No Active Filters</div>
  </ng-template>
</div>
