// Chart Headcount
export const arrDepChartNoSeries: Highcharts.Options = {
  caption: {
    useHTML: true,
    style: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '.6em',
    },
    text: 'Source: Live Data technologies',
  },
  chart: {
    backgroundColor: '#ffffff',
    events: {
      load: function () {
        const chart = this as any;
        const logoUrl = 'assets/img/imagotype2.svg';
        chart.customLogo = chart.renderer
          .image(logoUrl, chart.chartWidth - 131, chart.chartHeight - 34, 121, 24)
          .add();
      },
      redraw: function () {
        const chart = this as any; // Use 'any' type to add custom properties
        const logo = chart.customLogo;
        let logoWidth = 121;
        let logoHeight = 24;
        if (chart.chartWidth < 480) {
          logoWidth = 90; // Smaller width for smaller containers
          logoHeight = logoWidth / 5.04; // Maintain aspect ratio
        }
        if (logo) {
          logo.attr({
            x: chart.chartWidth - (logoWidth + 10),
            y: chart.chartHeight - (logoHeight + 10),
            width: logoWidth,
            height: logoHeight,
          });
        }
      },
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: 'Headcount',
    align: 'center',
    style: {
      fontWeight: '600',
    },
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%e %b %Y', // format for the dates on the xAxis
    },
    title: {
      style: {
        fontFamily: '"Montserrat", sans-serif',
      },
    },
  },
  yAxis: [
    {
      allowDecimals: false,
      title: {
        text: 'Number of Employees',
      },
      stackLabels: {
        enabled: false,
      },
      labels: {
        formatter: function () {
          return this.value.toLocaleString();
        },
      },
    },
  ],
  tooltip: {},
  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      cursor: 'pointer',
      states: {
        hover: {
          // enabled: false, // Disable default hover
        },
      },
      marker: {
        enabled: false, // Disable data points
      },
      dataLabels: {
        enabled: false,
      },
      linecap: 'round', // Make the lines rounded instead of angular
    },
    column: {
      stacking: 'normal',
    },
  },
  legend: {
    layout: 'horizontal',
    align: 'left',
    verticalAlign: 'top',
    itemStyle: {
      fontFamily: '"Montserrat", sans-serif',
    },
    labelFormatter: function () {
      // truncate the legend text and add an ellipsis if necessary
      const maxLength = 20;
      const text = this.name;
      return text.length > maxLength + 2 ? text.substring(0, maxLength) + '...' : text;
    },
  },
};

// Chart Tenure over time
export const tenureByMonthChartNoSeries: Highcharts.Options = {
  caption: {
    useHTML: true,
    style: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '.6em',
    },
    text: 'Source: Live Data technologies',
  },
  chart: {
    backgroundColor: '#ffffff',
    type: 'line',
    events: {
      load: function () {
        const chart = this as any;
        const logoUrl = 'assets/img/imagotype2.svg';
        chart.customLogo = chart.renderer
          .image(logoUrl, chart.chartWidth - 131, chart.chartHeight - 34, 121, 24)
          .add();
      },
      redraw: function () {
        const chart = this as any; // Use 'any' type to add custom properties
        const logo = chart.customLogo;
        let logoWidth = 121;
        let logoHeight = 24;
        if (chart.chartWidth < 480) {
          logoWidth = 90; // Smaller width for smaller containers
          logoHeight = logoWidth / 5.04; // Maintain aspect ratio
        }
        if (logo) {
          logo.attr({
            x: chart.chartWidth - (logoWidth + 10),
            y: chart.chartHeight - (logoHeight + 10),
            width: logoWidth,
            height: logoHeight,
          });
        }
      },
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: 'Tenure by Month',
    align: 'center',
    style: {
      fontWeight: '600',
    },
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%e %b %Y', // format for the dates on the xAxis
    },
    title: {
      style: {
        fontFamily: '"Montserrat", sans-serif',
      },
    },
  },
  yAxis: [
    {
      allowDecimals: false,
      title: {
        text: 'Average Tenure (months)',
      },
      stackLabels: {
        enabled: false,
      },
    },
  ],
  tooltip: {
    valueDecimals: 2,
    pointFormat: '{series.name}<br/><b>{point.y}</b> (N={point.sampleSize})',
  },
  plotOptions: {
    column: {
      stacking: 'normal',
    },
    series: {
      label: {
        connectorAllowed: false,
      },
      cursor: 'pointer',
      states: {
        hover: {
          // enabled: false, // Disable default hover
        },
      },
      marker: {
        enabled: false, // Disable data points
      },
      dataLabels: {
        enabled: false,
      },
      linecap: 'round', // Make the lines rounded instead of angular
    },
  },
  exporting: {
    sourceWidth: 1200,
  },
  legend: {
    layout: 'horizontal',
    align: 'left',
    verticalAlign: 'top',
    itemStyle: {
      fontFamily: '"Montserrat", sans-serif',
    },
    labelFormatter: function () {
      // truncate the legend text and add an ellipsis if necessary
      const maxLength = 20;
      const text = this.name;
      return text.length > maxLength + 2 ? text.substring(0, maxLength) + '...' : text;
    },
  },
};

// Chart Sankey
export const sankeyChartNoSeries: Highcharts.Options = {
  caption: {
    useHTML: true,
    style: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '.6em',
    },
    text: 'Source: Live Data technologies',
  },
  chart: {
    backgroundColor: '#ffffff',
    events: {
      load: function () {
        const chart = this as any;
        const logoUrl = 'assets/img/imagotype2.svg';
        const logoWidth = 121;
        const logoHeight = logoWidth / 5.05;
        const x = chart.chartWidth / 2 - logoWidth / 2;

        // Add the logo image and store the reference in customLogo
        chart.customLogo = chart.renderer
          .image(logoUrl, x, chart.chartHeight - logoHeight - 10, logoWidth, logoHeight)
          .add();
      },
      redraw: function () {
        const chart = this as any;
        const logo = chart.customLogo;

        if (!logo) return; // in case the logo is not initialized

        // Ensure the logo stays centered during a redraw (resize)
        let logoWidth = 121;
        let logoHeight = logoWidth / 5.05;

        if (chart.chartWidth < 480) {
          logoWidth = 90; // for smaller containers
          logoHeight = logoWidth / 5.05;
        }

        // Recalculate the x position to keep it centered
        const x = chart.chartWidth / 2 - logoWidth / 2;

        // Update the logo position and size
        logo.attr({
          x: x,
          y: chart.chartHeight - logoHeight - 10,
          width: logoWidth,
          height: logoHeight,
        });
      },
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: 'All Employee Movement',
    style: {
      fontWeight: '600',
    },
  },
  legend: { enabled: false },
  yAxis: { labels: { enabled: false } },
  plotOptions: {
    sankey: {
      nodePadding: 5,
      dataLabels: {
        enabled: true,
        style: {},
      },
    },
  },
  series: [
    {
      type: 'sankey',
      keys: ['from', 'to', 'weight'],
      data: [],
    },
  ],
  exporting: {
    sourceWidth: 1200,
  },
};

// Chart Poaching
export const wheelChartNoSeries: Highcharts.Options = {
  caption: {
    useHTML: true,
    style: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '.6em',
    },
    text: 'Source: Live Data technologies',
  },
  chart: {
    backgroundColor: '#ffffff',
    events: {
      load: function () {
        const chart = this as any;
        const logoUrl = 'assets/img/imagotype2.svg';
        chart.customLogo = chart.renderer
          .image(logoUrl, chart.chartWidth - 131, chart.chartHeight - 34, 121, 24)
          .add();
      },
      redraw: function () {
        const chart = this as any; // Use 'any' type to add custom properties
        const logo = chart.customLogo;
        let logoWidth = 121;
        let logoHeight = 24;
        if (chart.chartWidth < 480) {
          logoWidth = 90; // Smaller width for smaller containers
          logoHeight = logoWidth / 5.04; // Maintain aspect ratio
        }
        if (logo) {
          logo.attr({
            x: chart.chartWidth - (logoWidth + 10),
            y: chart.chartHeight - (logoHeight + 10),
            width: logoWidth,
            height: logoHeight,
          });
        }
      },
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: 'Inter-company Movement',
    style: {
      fontWeight: '600',
    },
  },
  legend: { enabled: false },
  yAxis: { labels: { enabled: false } },
  plotOptions: {
    sankey: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  series: [
    {
      type: 'dependencywheel',
      keys: ['from', 'to', 'weight'],
      data: [],
    },
  ],
  exporting: {
    sourceWidth: 1200,
  },
};

export const tenureCohortChart: Highcharts.Options = {
  caption: {
    useHTML: true,
    style: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '.6em',
    },
    text: 'Source: Live Data technologies',
  },
  chart: {
    parallelCoordinates: true,
    parallelAxes: {
      labels: {
        style: {
          color: '#999999',
        },
      },
      lineWidth: 2,
      showFirstLabel: false,
      showLastLabel: false,
    },
    polar: true,
    backgroundColor: '#ffffff',
    events: {
      load: function () {
        const chart = this as any;
        const logoUrl = 'assets/img/imagotype2.svg';
        chart.customLogo = chart.renderer
          .image(logoUrl, chart.chartWidth - 131, chart.chartHeight - 34, 121, 24)
          .add();
      },
      redraw: function () {
        const chart = this as any; // Use 'any' type to add custom properties
        const logo = chart.customLogo;
        let logoWidth = 121;
        let logoHeight = 24;
        if (chart.chartWidth < 480) {
          logoWidth = 90; // Smaller width for smaller containers
          logoHeight = logoWidth / 5.04; // Maintain aspect ratio
        }
        if (logo) {
          logo.attr({
            x: chart.chartWidth - (logoWidth + 10),
            y: chart.chartHeight - (logoHeight + 10),
            width: logoWidth,
            height: logoHeight,
          });
        }
      },
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: 'Current Employee Tenure Breakdown',
    align: 'center',
    style: {
      fontWeight: '600',
    },
  },
  xAxis: {
    categories: ['0-2 years', '3-4 years', '5-7 years', '8-10 years', '11+ years'],
    labels: {
      distance: 30,
      style: {
        fontWeight: 'bold',
      },
    },
    gridLineWidth: 0,
    tickmarkPlacement: 'on',
    lineWidth: 0,
  },
  yAxis: [
    {
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      min: 0,
      allowDecimals: false,
      title: {
        text: undefined,
      },
      stackLabels: {
        enabled: false,
      },
      labels: {
        formatter: function () {
          const pointValue = this.value as number;
          return pointValue.toFixed(0) + '%';
        },
      },
    },
  ],
  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      cursor: 'pointer',
      states: {
        hover: {
          // enabled: false, // Disable default hover
        },
      },
      marker: {
        enabled: false, // Disable data points
      },
      dataLabels: {
        enabled: false,
      },
      linecap: 'round', // Make the lines rounded instead of angular
    },
    column: {
      stacking: 'normal',
    },
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.y:.2f}%</b>',
  },
  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
    y: 20,
    width: '25%',
    itemStyle: {
      fontFamily: '"Montserrat", sans-serif',
    },
    labelFormatter: function () {
      // truncate the legend text and add an ellipsis if necessary
      const maxLength = 20;
      const text = this.name;
      return text.length > maxLength + 2 ? text.substring(0, maxLength) + '...' : text;
    },
  },
  pane: {
    size: '80%',
  },
};

// Chart Tenure over time
export const churnRateByMonthChart: Highcharts.Options = {
  caption: {
    useHTML: true,
    style: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '.6em',
    },
    text: 'Source: Live Data technologies',
  },
  chart: {
    backgroundColor: '#ffffff',
    type: 'line',
    events: {
      load: function () {
        const chart = this as any;
        const logoUrl = 'assets/img/imagotype2.svg';
        chart.customLogo = chart.renderer
          .image(logoUrl, chart.chartWidth - 131, chart.chartHeight - 34, 121, 24)
          .add();
      },
      redraw: function () {
        const chart = this as any; // Use 'any' type to add custom properties
        const logo = chart.customLogo;
        let logoWidth = 121;
        let logoHeight = 24;
        if (chart.chartWidth < 480) {
          logoWidth = 90; // Smaller width for smaller containers
          logoHeight = logoWidth / 5.04; // Maintain aspect ratio
        }
        if (logo) {
          logo.attr({
            x: chart.chartWidth - (logoWidth + 10),
            y: chart.chartHeight - (logoHeight + 10),
            width: logoWidth,
            height: logoHeight,
          });
        }
      },
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: 'Churn Rate by Month',
    align: 'center',
    style: {
      fontWeight: '600',
    },
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%e %b %Y', // format for the dates on the xAxis
    },
    title: {
      style: {
        fontFamily: '"Montserrat", sans-serif',
      },
    },
  },
  yAxis: [
    {
      allowDecimals: false,
      title: {
        text: '% of employees that departed',
      },
      stackLabels: {
        enabled: false,
      },
    },
  ],
  tooltip: {
    valueDecimals: 2,
    pointFormat: '{series.name}: <b>{point.y:.2f}%</b>',
  },
  plotOptions: {
    column: {
      stacking: 'normal',
    },
    series: {
      label: {
        connectorAllowed: false,
      },
      cursor: 'pointer',
      states: {
        hover: {
          // enabled: false, // Disable default hover
        },
      },
      marker: {
        enabled: false, // Disable data points
      },
      dataLabels: {
        enabled: false,
      },
      linecap: 'round', // Make the lines rounded instead of angular
    },
  },
  exporting: {
    sourceWidth: 1200,
  },
  legend: {
    layout: 'horizontal',
    align: 'left',
    verticalAlign: 'top',
    itemStyle: {
      fontFamily: '"Montserrat", sans-serif',
    },
    labelFormatter: function () {
      // truncate the legend text and add an ellipsis if necessary
      const maxLength = 20;
      const text = this.name;
      return text.length > maxLength + 2 ? text.substring(0, maxLength) + '...' : text;
    },
  },
};
