import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  CPGetCompanyResponse,
  Company,
  CompanyPagesService,
  GetCompanyPages200Response,
  GetCompanyPagesRequest,
} from 'ldt-moneyball-api';
import { Observable, catchError, debounceTime, filter, map, of, switchMap, tap } from 'rxjs';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { NotificationService } from '../notification-service/notification.service';

@Component({
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss'],
})
export class CompanySearchComponent implements OnChanges, AfterViewInit {
  @Input() disabled: boolean = false;
  @Input() disabledText: string = 'Disabled';
  @Input() resetAfterSelection: boolean = false;
  @Input() labelText: string = 'Enter company name...';
  @Input() identifier!: string; // identifier input
  @Input() floatLabel: 'auto' | 'always' = 'auto'; // default to auto but can be set to always

  @ViewChild('inputField') inputField?: ElementRef;
  @ViewChild('autoInput') autocompleteTrigger!: MatAutocompleteTrigger;

  @Output() companySelected = new EventEmitter<{ company: Company; identifier: string }>();

  searching: boolean = false;
  companyNameControl = new FormControl<string>('');
  searchCompanies: Observable<Company[]>;
  selectedCompany: Company | undefined;

  constructor(
    private companyPagesService: CompanyPagesService,
    private notify: NotificationService
  ) {
    // Auto-complete on company name
    this.searchCompanies = this.companyNameControl.valueChanges.pipe(
      filter((c) => c !== null && c.length >= 1), // Bump this to only search if a certain number of chars is typed
      tap(() => {
        this.searching = true;
      }),
      debounceTime(100), // When someone is typing, don't submit on every single keypress
      switchMap((c) => {
        const req: GetCompanyPagesRequest = {
          report_name: GetCompanyPagesRequest.ReportNameEnum.Search,
          search_string: c as string,
        };
        // Use an intermediate observable to catch the error and return undefined
        return this.companyPagesService.getCompanyPages(req).pipe(
          catchError(() => {
            return of(undefined);
          })
        );
      }),
      map((d: GetCompanyPages200Response | undefined) => {
        // If there was an error searching for the company
        if (!d) {
          // this.showError("Error searching for companies. Try another search term.");
          this.searching = false;
          return [];
        }
        // We cheat with any here because the API response doesn't have a type for the company search report
        const data = d as any;
        this.selectedCompany = undefined;
        this.searching = false;
        return data.companies || [];
      }),
      catchError(() => {
        // This kind of error shouldn't really happen, but if it does, this observable dies
        this.notify.error('Something unexpected happened. Please reload the page and try again');
        this.searching = false;
        return of([]);
      })
    );
  }

  ngAfterViewInit(): void {
    // setTimeout is needed to focus the input field after the view is initialized, to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.focusInputField();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      if (this.disabled) {
        this.selectedCompany = undefined;
        this.companyNameControl.disable();
        this.companyNameControl.reset(this.disabledText, { onlySelf: true, emitEvent: false });
      } else {
        this.companyNameControl.enable();
        this.companyNameControl.reset('', { onlySelf: true, emitEvent: false });
      }
    }
  }

  onCompanySelected(event: MatAutocompleteSelectedEvent): void {
    if (!event.option) {
      console.error('No option selected');
      return;
    }
    const selectedCompany = event.option.value as Company;
    this.toggleCompany(selectedCompany);
  }

  toggleCompany(c: Company): void {
    this.selectedCompany = c;

    this.companyNameControl.reset(c.name, { onlySelf: true, emitEvent: false });
    this.autocompleteTrigger.closePanel();

    this.companySelected.emit({ company: c, identifier: this.identifier }); // Emit the selected company data with identifier

    if (this.resetAfterSelection) {
      this.companyNameControl.reset('', { onlySelf: true, emitEvent: false });
    }
  }

  // focus input field when user clicks on 'Add company' button
  focusInputField() {
    if (this.inputField) {
      this.inputField.nativeElement.focus();
    }
  }
}
